import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['producerTemplate', 'producersContainer'];

  connect() {
    this.updateDropdowns();
  }

  addProducer = (event) => {
    event.preventDefault();
    const button = event.currentTarget;

    if (button.dataset.disabled === 'true') return;

    const collapseContainer = button.closest('.collapse');
    const container = collapseContainer?.querySelector('.producers-container');
    const template = collapseContainer?.querySelector('template[data-ecc-audits-target="producerTemplate"]');

    if (container && template) {
      const timestamp = Date.now();
      const auditPiLineId = button.dataset.eccAuditPiLineId;

      const clone = template.content.cloneNode(true);
      this.updateAttributes(clone, timestamp);

      const selectElement = clone.querySelector('select');
      if (selectElement) {
        selectElement.addEventListener('change', this.handleProducerChange);
      }

      if (auditPiLineId) {
        clone.appendChild(this.createHiddenInput(auditPiLineId, timestamp));
      }

      container.appendChild(clone);
      this.updateDropdowns();
    }
  };

  removeProducer = (event) => {
    event.preventDefault();
    const producerField = event.target.closest('.producer-field');

    if (producerField) {
      const destroyInput = producerField.querySelector('input[name*="_destroy"]');

      if (destroyInput) {
        destroyInput.value = '1';
        producerField.style.display = 'none';
      } else {
        producerField.remove();
      }

      this.updateDropdowns();
    }
  };

  handleProducerChange = () => {
    this.updateDropdowns();
  };

  updateDropdowns = () => {
    const allSelects = this.element.querySelectorAll('select[name*="[producer_id]"]');

    const selectedProducerIds = new Set(
      [...allSelects]
        .filter((select) => {
          const destroyInput = select.closest('.producer-field')?.querySelector('input[name*="_destroy"]');
          return !destroyInput || destroyInput.value !== '1';
        })
        .map((select) => select.value)
        .filter((value) => value !== ''),
    );

    allSelects.forEach((select) => {
      const currentValue = select.value;

      [...select.options].forEach((option) => {
        option.hidden = option.value !== '' && option.value !== currentValue
          && selectedProducerIds.has(option.value);
      });
    });

    this.updateAddButtons();
  };

  updateAddButtons = () => {
    const addButtons = this.element.querySelectorAll('.add-btn[data-action*="addProducer"]');

    addButtons.forEach((button) => {
      const collapseContainer = button.closest('.collapse');

      if (collapseContainer) {
        const allProducers = this.getProducers(collapseContainer, false);
        const selectedProducers = this.getProducers(collapseContainer, true);

        const addIcon = button.querySelector('.add-icon');
        const isDisabled = allProducers.length === selectedProducers.length;

        button.dataset.disabled = isDisabled.toString();
        addIcon.classList.toggle('disabled-icon', isDisabled);
      }
    });
  };

  getProducers = (container, selected = false) => {
    const selects = container.querySelectorAll('select[name*="[producer_id]"]');

    if (selected) {
      return [...selects]
        .filter((select) => {
          const destroyInput = select.closest('.producer-field')?.querySelector('input[name*="_destroy"]');
          return !destroyInput || destroyInput.value !== '1';
        })
        .map((select) => select.value)
        .filter((value) => value !== '');
    }

    const select = container.querySelector('select[name*="[producer_id]"]');
    return select
      ? [...select.options].filter((option) => option.value !== '').map((option) => option.value)
      : [];
  };

  updateAttributes = (clone, timestamp) => {
    const elements = clone.querySelectorAll('[name], [id], [for]');

    elements.forEach((element) => {
      ['name', 'id', 'for'].forEach((attr) => {
        if (element.hasAttribute(attr)) {
          element.setAttribute(
            attr,
            element.getAttribute(attr).replace(/new_pi_line|new_producer/g, timestamp),
          );
        }
      });
    });
  };

  createHiddenInput = (auditPiLineId, timestamp) => {
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = `ecc_audit[ecc_audit_pi_lines_attributes][${timestamp}][id]`;
    hiddenInput.value = auditPiLineId;
    return hiddenInput;
  };
}
